import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

const Example = () => {
    const [narrativeId, setNarrativeId] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/example_narrative`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                const data = await response.json();
                if (data && data.id) {
                    setNarrativeId(data.id);
                } else {
                    setError(new Error("Narrative ID not found in the response"));
                }
            } catch (error) {
                setError(error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (narrativeId) {
            navigate(`/narratives/${narrativeId}`);
        }
    }, [narrativeId, navigate]);

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return <p>Loading...</p>;
};

export default Example;
