import React, { useEffect, useState, useContext } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { UserContext } from "../context/UserContext";
import { API_BASE_URL } from '../config';

const GeoJsonDisplay = ({ narrativeId }) => {
    const [geoJsonData, setGeoJsonData] = useState(null);
    const { token } = useContext(UserContext);

    useEffect(() => {
        const fetchGeoJson = async () => {
            try {
                const requestOptions = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                };
                const response = await fetch(`${API_BASE_URL}/narratives/${narrativeId}/mappings`, requestOptions);
                const data = await response.json();
                setGeoJsonData(data[0]?.location || null);
            } catch (error) {
                console.error('Error fetching GeoJSON:', error);
            }
        };
        fetchGeoJson();
    }, [narrativeId, token]);

    const FitBoundsToGeoJson = ({ geoJson }) => {
        const map = useMap();
        useEffect(() => {
            if (geoJson) {
                try {
                    const bounds = L.geoJSON(geoJson).getBounds();
                    if (bounds.isValid()) {
                        map.fitBounds(bounds);
                    } else {
                        console.warn("GeoJSON bounds are not valid.");
                    }
                } catch (error) {
                    console.error("Error fitting bounds:", error);
                }
            } else {
                // Set view to default location when no GeoJSON data
                map.setView([68.7377, 15.4254], 10);
            }
        }, [geoJson, map]);
        return null;
    };

    const onEachFeature = (feature, layer) => {
        const notes = feature.properties?.notes || '';
        const popupContent = `
            <div class="box">
                <p>${notes || 'No notes available'}</p>
            </div>
        `;
        layer.bindPopup(popupContent);
    };

    return (
        <div>
            <MapContainer
                style={{ width: '100%', height: '500px' }}
                center={[68.7377, 15.4254]} // Default center
                zoom={10} // Default zoom
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {geoJsonData && <GeoJSON data={geoJsonData} onEachFeature={onEachFeature} />}
                <FitBoundsToGeoJson geoJson={geoJsonData} />
            </MapContainer>
        </div>
    );
};

export default GeoJsonDisplay;