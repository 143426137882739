import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../context/UserContext';
import { API_BASE_URL, IMAGES_BASE_URL } from '../config';

const NarrativeImages = ({ narrativeId, imageList }) => {
    const [newImage, setNewImage] = useState(null);
    const [newImageTitle, setNewImageTitle] = useState('');
    const [newImageDescription, setNewImageDescription] = useState('');
    const { token } = useContext(UserContext);
    const [newCopyRightInfo, setNewCopyRightInfo] = useState('');
    const [isModalActive, setIsModalActive] = useState(false);
    const [editingImage, setEditingImage] = useState(null);
    const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const [images, setImages] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const fileInputRef = useRef(null); // Create a ref for the file input

    useEffect(() => {
        const fetchImageDetails = async (imageList) => {
            try {
                const imageDetails = await Promise.all(
                    imageList.map(async (imageId) => {
                        const response = await fetch(`${API_BASE_URL}/images/${imageId}`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: 'Bearer ' + token,
                            },
                        });
                        const data = await response.json();
                        return {
                            id: imageId,
                            title: data.title,
                            description: data.description,
                            copyright_info: data.copyright_info
                        };
                    })
                );
                setImages(imageDetails);
            } catch (error) {
                setErrorMessage('Error fetching image details');
            }
        };
        if (imageList && imageList.length > 0) {
            fetchImageDetails(imageList);
        }
    }, [imageList, token]);

    const handleCopyRightInfoChange = (e) => {
        setNewCopyRightInfo(e.target.value);
    };

    const handleImageChange = (e) => {
        setNewImage(e.target.files[0]);
    };
    const handleImageTitleChange = (e) => {
        setNewImageTitle(e.target.value);
    };
    const handleImageDescriptionChange = (e) => {
        setNewImageDescription(e.target.value);
    };

    const handleDeleteImage = async (imageId) => {
        setIsDeleteModalActive(true);
        setImageToDelete(imageId);
    };

    const confirmDeleteImage = async () => {
        if (!imageToDelete) return;

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        };

        try {
            const response = await fetch(`${API_BASE_URL}/images/${imageToDelete}`, requestOptions);
            if (response.ok) {
                setImages(images.filter(image => image.id !== imageToDelete));
                setIsDeleteModalActive(false);
                setImageToDelete(null);
            } else {
                const data = await response.json();
                setErrorMessage(data.detail);
            }
        } catch (error) {
            setErrorMessage('Error deleting image');
        }
    };

    const openModal = (image = null) => {
        setEditingImage(image);
        if (image) {
            setNewImageTitle(image.title);
            setNewImageDescription(image.description);
            setNewCopyRightInfo(image.copyright_info);
        }
        setIsModalActive(true);
    };

    const closeModal = () => {
        setIsModalActive(false);
        setNewImage(null);
        setNewImageTitle('');
        setNewImageDescription('');
        setNewCopyRightInfo('');
        setEditingImage(null);
        setErrorMessage('');
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
        }
    };

    const closeDeleteModal = () => {
        setIsDeleteModalActive(false);
        setImageToDelete(null);
    };

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    const validateForm = () => {
        if (!newImage && !editingImage) {
            setErrorMessage('Please select an image to upload');
            return false;
        }
        if (!newImageTitle) {
            setErrorMessage('Please enter an image title');
            return false;
        }
        if (!newImageDescription) {
            setErrorMessage('Please enter an image description');
            return false;
        }
        if (!newCopyRightInfo) {
            setErrorMessage('Please enter copyright info');
            return false;
        }
        return true;
    };

    const handleUploadImage = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const formData = new FormData();
        formData.append('files', newImage);
        formData.append('title', newImageTitle);
        formData.append('description', newImageDescription);
        formData.append('copyright_info', newCopyRightInfo);

        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            body: formData,
        };

        try {
            const response = await fetch(`${API_BASE_URL}/narratives/${narrativeId}/images/`, requestOptions);
            const data = await response.json();

            if (response.ok) {
                setImages([...images, { id: data.id, title: newImageTitle, description: newImageDescription, copyright_info: newCopyRightInfo }]);
                closeModal();
            } else {
                setErrorMessage(data.detail);
            }
        } catch (error) {
            setErrorMessage('Error uploading image');
        }
    };

    const handleEditImage = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const formData = new FormData();
        if (newImage) {
            formData.append('files', newImage);
        }
        formData.append('title', newImageTitle);
        formData.append('description', newImageDescription);
        formData.append('copyright_info', newCopyRightInfo);

        const requestOptions = {
            method: 'PUT',
            headers: {
                Authorization: 'Bearer ' + token,
            },
            body: formData,
        };

        try {
            const response = await fetch(`${API_BASE_URL}/images/${editingImage.id}`, requestOptions);
            const data = await response.json();

            if (response.ok) {
                setImages(images.map(img => img.id === editingImage.id ? { ...img, title: newImageTitle, description: newImageDescription, copyright_info: newCopyRightInfo } : img));
                closeModal();
            } else {
                setErrorMessage(data.detail);
            }
        } catch (error) {
            setErrorMessage('Error editing image');
        }
    };

    return (
        <div className="box">
            <div className="columns is-vcentered">
                <div className="column">
                    <h3 className="title is-5">Images</h3>
                </div>
            </div>
            <div className="field">
                <div className="control">
                    {images.map((image) => (
                        <article className="media image-card" key={image.id}>
                            <figure className="media-left">
                                <p className="image is-128x128">
                                    <img src={`${IMAGES_BASE_URL}/${image.id}`} alt={`Image ${image.title}`} />
                                </p>
                            </figure>
                            <div className="media-content">
                                <div className="content">
                                    <p>
                                        <strong>{image.title}</strong>
                                        <br />
                                        {truncateText(image.description, 100)}
                                        <br />
                                        <small>{image.copyright_info}</small>
                                    </p>
                                </div>
                            </div>
                            <div className="media-right">
                                <button
                                    className="button is-link is-small"
                                    onClick={() => openModal(image)}
                                    style={{ marginRight: '10px' }}
                                >
                                    Edit
                                </button>
                                <button
                                    className="button is-danger is-small"
                                    onClick={() => handleDeleteImage(image.id)}
                                    style={{ marginRight: '10px' }}
                                >
                                    Delete
                                </button>
                            </div>
                        </article>
                    ))}
                </div>
            </div>
            <div className={`modal ${isModalActive ? 'is-active' : ''}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">{editingImage ? 'Edit Image' : 'Upload New Image'}</p>
                        <button className="delete" aria-label="close" onClick={closeModal}></button>
                    </header>
                    <form onSubmit={editingImage ? handleEditImage : handleUploadImage}>
                        <section className="modal-card-body">
                            {errorMessage && <p className="has-text-danger">{errorMessage}</p>}
                            {!editingImage && (
                                <div className="field">
                                    <label className="label" htmlFor="newImage">Image File</label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type="file"
                                            id="newImage"
                                            onChange={handleImageChange}
                                            ref={fileInputRef} // Add ref to the file input
                                            required
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="field">
                                <label className="label" htmlFor="newImageTitle">Image Title</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        type="text"
                                        id="newImageTitle"
                                        value={newImageTitle}
                                        onChange={handleImageTitleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label" htmlFor="newImageDescription">Image Description</label>
                                <div className="control">
                                    <textarea
                                        className="textarea"
                                        id="newImageDescription"
                                        value={newImageDescription}
                                        onChange={handleImageDescriptionChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label" htmlFor="newCopyRightInfo">Copyright Info</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        id="newCopyRightInfo"
                                        value={newCopyRightInfo}
                                        onChange={handleCopyRightInfoChange}
                                        required
                                    />
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <button type="submit" className="button is-link">
                                {editingImage ? 'Save Changes' : 'Upload Image'}
                            </button>
                            <button type="button" className="button" onClick={closeModal}>Cancel</button>
                        </footer>
                    </form>
                </div>
            </div>
            <div className="columns is-vcentered">
                <div className="column has-text-centered">
                    <button className="button is-link" onClick={() => openModal()}>Upload New Image</button>
                </div>
            </div>
            <div className={`modal ${isDeleteModalActive ? 'is-active' : ''}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Confirm Delete</p>
                        <button className="delete" aria-label="close" onClick={closeDeleteModal}></button>
                    </header>
                    <section className="modal-card-body">
                        <p>Are you sure you want to delete "{images.find(image => image.id === imageToDelete)?.title}"?</p>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={confirmDeleteImage}>Delete</button>
                        <button className="button" onClick={closeDeleteModal}>Cancel</button>
                    </footer>
                </div>
            </div>
        </div>
    );
};

export default NarrativeImages;
