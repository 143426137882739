import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import ErrorMessage from "./ErrorMessage";
import { UserContext } from "../context/UserContext";
import { API_BASE_URL, IMAGES_BASE_URL } from '../config';

const NarrativeList = () => {
    const { token } = useContext(UserContext);
    const [narratives, setNarratives] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        getNarratives();
    }, [currentPage]);

    const handleViewDetails = (id) => {
        navigate(`/narratives/${id}`);
    };

    const getNarratives = async () => {
        const skip = (currentPage - 1) * limit;
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        };

        try {
            const response = await fetch(`${API_BASE_URL}/public_narratives?limit=${limit}&skip=${skip}`, requestOptions);
            if (!response.ok) {
                const data = await response.json();
                setErrorMessage(data.detail);
            } else {
                const data = await response.json();
                setNarratives(data.narratives);
                setTotalPages(Math.ceil(data.total_count / limit));
                setLoaded(true);
            }
        } catch (error) {
            setErrorMessage("Failed to fetch narratives. Please try again later.");
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const renderPagination = () => {
        const pages = [];
        const pageRange = 2;
        const startPage = Math.max(1, currentPage - pageRange);
        const endPage = Math.min(totalPages, currentPage + pageRange);

        if (startPage > 1) {
            pages.push(
                <li key="first">
                    <button className="pagination-link" onClick={() => handlePageChange(1)}>1</button>
                </li>
            );
            if (startPage > 2) {
                pages.push(<li key="ellipsis-start"><span className="pagination-ellipsis">&hellip;</span></li>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li key={i}>
                    <button
                        className={`pagination-link ${i === currentPage ? "is-current" : ""}`}
                        aria-label={`Goto page ${i}`}
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </button>
                </li>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(<li key="ellipsis-end"><span className="pagination-ellipsis">&hellip;</span></li>);
            }
            pages.push(
                <li key="last">
                    <button className="pagination-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                </li>
            );
        }

        return pages;
    };

    return (
        <>
            <h2 className="title is-2 has-text-centered">Narratives</h2>
            <ErrorMessage message={errorMessage} />
            {loaded && narratives ? (
                <div>
                    {narratives.map((narrative) => (
                        <div key={narrative.id} className="card mb-4">
                            <div className="card-content p-3">
                                <div className="media" style={{ alignItems: 'center' }}>
                                    <div className="media-left">
                                        <figure className="image is-128x128" style={{ overflow: 'hidden' }}>
                                            <img
                                                src={`${IMAGES_BASE_URL}/${narrative.image_ids[0]}`}
                                                alt="Narrative"
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                            />
                                        </figure>
                                    </div>
                                    <div className="media-content">
                                        <p className="title is-4 has-text-left">{narrative.title}</p>
                                        <p className="subtitle is-6 has-text-left">{moment(narrative.updated_at).format("MMMM Do, YYYY, HH:mm")}</p>
                                    </div>
                                    <div className="media-right">
                                        <button className="button is-link mr-5" onClick={() => handleViewDetails(narrative.id)}>View</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {totalPages > 1 && (
                        <nav className="pagination is-centered" role="navigation" aria-label="pagination">
                            {currentPage > 1 && (
                                <button className="pagination-previous" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                            )}
                            {currentPage < totalPages && (
                                <button className="pagination-next" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                            )}
                            <ul className="pagination-list">
                                {renderPagination()}
                            </ul>
                        </nav>
                    )}
                </div>
            ) : <p className="has-text-centered">Loading...</p>}
        </>
    );
};

export default NarrativeList;
