import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';

import ImageCarousel from './ImageCarousel';
import GeoJsonDisplay from './GeoJsonDisplay';
import { UserContext } from '../context/UserContext';
import { API_BASE_URL } from '../config';

const NarrativeMain = ({ startingId }) => {
    const { id: narrativeIdFromUrl } = useParams(); // Get the narrative ID from the URL
    const { token, userId } = useContext(UserContext);
    const [title, setTitle] = useState(null);
    const [content, setContent] = useState(null);
    const [authorId, setAuthorId] = useState(null); // Add authorId state
    const [hasImages, setHasImages] = useState(false);
    const [hasLocation, setHasLocation] = useState(false);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [notFound, setNotFound] = useState(false);

    const narrativeId = startingId || narrativeIdFromUrl;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const requestOptions = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                const response = await fetch(`${API_BASE_URL}/narratives/${narrativeId}`, requestOptions);

                if (response.status === 404) {
                    setNotFound(true);
                    setLoading(false);
                    return;
                }

                const data = await response.json();

                setTitle(data.title);
                setContent(data.content);
                setAuthorId(data.author_id); // Set the authorId

                // Check if the length of the image_ids array is greater than 0
                if (data.image_ids.length > 0) {
                    setHasImages(true);
                } else {
                    setHasImages(false);
                }

                if (data.mapping_ids.length > 0) {
                    setHasLocation(true);
                } else {
                    setHasLocation(false);
                }

                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [narrativeId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (notFound) {
        return <p>Page Not Found</p>;
    }

    if (error) {
        return <p>Error: Page Not Found, {error.message}</p>;
    }

    return (
        <div>
            <div>
                {token && userId === authorId && (
                    <Link to={`/narratives/${narrativeId}/edit`}>
                        <button className="button is-primary is-small" style={{ float: 'right' }}>Edit</button>
                    </Link>
                )}
                <h1 style={{ marginTop: 20 }} className="title">{title}</h1>
            </div>

            <div style={{ marginTop: 20 }}>
                <p>{content}</p>
            </div>

            {hasLocation && (
                <div style={{ marginTop: 20 }}>
                    <h2>Location</h2>
                    <GeoJsonDisplay narrativeId={narrativeId} />
                </div>
            )}

            {hasImages && (
                <div style={{ marginTop: 20 }}>
                    <h2>Attached Images</h2>
                    <ImageCarousel narrativeId={narrativeId} />
                </div>
            )}
        </div>
    );
};

export default NarrativeMain;
