import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { API_BASE_URL, IMAGES_BASE_URL } from '../config';

const ImageCarousel = ({ narrativeId }) => {
    const [images, setImages] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    useEffect(() => {
        const getImages = async () => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    // Authorization: "Bearer " + token,  // Uncomment and configure if auth is needed
                },
            };

            const response = await fetch(`${API_BASE_URL}/narratives/${narrativeId}/images`, requestOptions);
            const data = await response.json();

            if (!response.ok) {
                setErrorMessage(data.detail || 'Failed to fetch images');  // Adjust error handling
            } else {
                setImages(data); // Assuming 'data' is an array of image objects
            }
        };


        getImages(); // This is the call to the function
    }, []); // Empty dependency array means this effect runs only once after the component mounts


    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "rgba(125,125,125,0.5)", borderRadius: '50%' }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "rgba(125,125,125,0.5)", borderRadius: '50%' }}
                onClick={onClick}
            />
        );
    }

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: nav2,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />
    };

    // const thumbnailHeight = '80px';  // Example height, adjust as needed
    const settingsThumbs = {
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: nav1,
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        infinite: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        style: { height: '80px', width: '100%' }
    };

    // Styles for thumbnail container
    const thumbnailContainerStyle = {
        height: '80px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    // Styles for thumbnail images
    const thumbnailImageStyle = {
        height: '80px',  // This enforces a consistent height for all thumbnails
        width: '100%',   // This maintains the aspect ratio
        objectFit: 'cover' // This ensures the image covers the area without distortion
    };

    return (
        <div>
            <div style={{ marginTop: 20 }}>
                <Slider {...settingsThumbs} ref={slider => setNav2(slider)}>
                    {images.map(image => (
                        <div key={image.id} style={thumbnailContainerStyle}>
                            <img src={`${IMAGES_BASE_URL}/${image.id}`} alt={image.title} style={thumbnailImageStyle} />
                        </div>
                    ))}
                </Slider>
            </div>
            <div style={{ marginTop: 20 }}>
                <Slider {...settingsMain} ref={slider => setNav1(slider)}>
                    {images.map(image => (
                        <div key={image.id}>
                            <img src={`${IMAGES_BASE_URL}/${image.id}`} alt={image.title} style={{ width: '100%', height: 'auto' }} />
                            <h3>{image.title}</h3>
                            <p style={{ textAlign: 'left' }}>Description: {image.description}</p>
                            <p style={{ textAlign: 'left' }}>Copyright: {image.copyright_info}</p>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default ImageCarousel;
