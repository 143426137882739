import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import ErrorMessage from './ErrorMessage';

import NarrativeImages from './NarrativeImages';
import NarrativeGeoJson from './NarrativeGeoJson';
import '../static/NarrativeEdit.css'; // Import the CSS file
import { API_BASE_URL } from '../config';

const NarrativeEdit = () => {
    const { id: narrativeId } = useParams(); // Get the narrative ID from the URL
    const { token } = useContext(UserContext);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [images, setImages] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [location, setLocation] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchNarrative = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
            };

            try {
                const response = await fetch(`${API_BASE_URL}/narratives/${narrativeId}`, requestOptions);
                const data = await response.json();

                if (response.ok) {
                    setTitle(data.title);
                    setContent(data.content || '');
                    setImages(data.image_ids);
                    if (data.mapping_ids.length === 0) {
                        setLocation('');
                    } else {
                        setLocation(data.mapping_ids);
                    }
                } else {
                    setErrorMessage(data.detail);
                }
            } catch (error) {
                setErrorMessage('Error fetching narrative details');
            }
        };
        fetchNarrative();
    }, [narrativeId, token]);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleContentChange = (e) => {
        setContent(e.target.value);
    };

    const handleSave = async (publish = false) => {
        const updateData = {};
        if (title !== undefined) updateData.title = title;
        if (content !== undefined) updateData.content = content;
        if (images.length > 0) updateData.images = images.map(img => img.id);
        if (location) updateData.location = location;
        if (publish) updateData.published = true;

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(updateData),
        };

        try {
            const response = await fetch(`${API_BASE_URL}/narratives/${narrativeId}`, requestOptions);
            const data = await response.json();

            if (response.ok) {
                navigate(`/narratives/${narrativeId}`);
            } else {
                setErrorMessage(data.detail);
            }
        } catch (error) {
            setErrorMessage('Error saving narrative');
        }
    };

    const handlePublish = async () => {
        await handleSave(true);
    };

    const handleCancel = () => {
        navigate(`/narratives/${narrativeId}`);
    };

    return (
        <div className="box mt-5">
            <div className="columns is-vcentered">
                <div className="column">
                    <h2 className="title is-4">Edit Narrative</h2>
                </div>
                <div className="column is-narrow">
                    <button className="button is-primary" onClick={handlePublish}>Publish</button>
                </div>
            </div>
            <ErrorMessage message={errorMessage} />
            <div className="card">
                <div className="card-content">
                    <div className="field">
                        <label className="label" htmlFor="title">Title</label>
                        <div className="control">
                            <input
                                className="input"
                                type="text"
                                id="title"
                                value={title}
                                onChange={handleTitleChange}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label" htmlFor="content">Content</label>
                        <div className="control">
                            <textarea
                                className="textarea"
                                id="content"
                                value={content || ''}
                                onChange={handleContentChange}
                            />
                        </div>
                    </div>
                    <div className="field">
                        <div className="control is-pulled-right">
                            <div className="buttons">
                                <button className="button is-link" onClick={() => handleSave(false)}>Save</button>
                                <button className="button is-light" onClick={handleCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div className="is-clearfix"></div>
                </div>
            </div>
            <hr />
                <NarrativeImages narrativeId={narrativeId} imageList={images} />
            <hr />
                <NarrativeGeoJson narrativeId={narrativeId} originalLocation={location} />
            <hr />
        </div>
    );
};

export default NarrativeEdit;
