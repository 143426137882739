import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import GeoJsonDisplay from './GeoJsonDisplay';
import { API_BASE_URL } from '../config';

const NarrativeGeoJson = ({ narrativeId, originalLocation }) => {
    const { token } = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [location, setLocation] = useState('');
    const [isDeleteModalActive, setIsDeleteModalActive] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLocation(originalLocation);
    }, [originalLocation]);

    const handleGeoJsonEditClick = () => navigate(`/narratives/${narrativeId}/geojson`);

    const handleDeleteLocation = () => setIsDeleteModalActive(true);

    const closeDeleteModal = () => setIsDeleteModalActive(false);

    const confirmDeleteLocation = async () => {
        if (!location) return;

        try {
            const response = await fetch(`${API_BASE_URL}/narratives/${narrativeId}/mappings/${location}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                setLocation('');
                setIsDeleteModalActive(false);
                setErrorMessage(''); // Clear any previous error messages
            } else {
                const data = await response.json();
                setErrorMessage(data.detail || 'Error deleting location');
            }
        } catch (error) {
            setErrorMessage('Error deleting location');
        }
    };

    return (
        <div>
            {errorMessage && (
                <div className="notification is-danger">
                    <button className="delete" onClick={() => setErrorMessage('')}></button>
                    {errorMessage}
                </div>
            )}
            <div className="box">
                <div className="columns is-vcentered is-mobile">
                    <div className="column">
                        <div className="is-flex is-justify-content-space-between is-align-items-center">
                            <h3 className="title is-5 mb-0">Location</h3>
                            {location && (
                                <div className="buttons">
                                    <button className="button is-link" onClick={handleGeoJsonEditClick}>Edit</button>
                                    <button className="button is-danger" onClick={handleDeleteLocation}>Delete</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {!location ? (
                    <div className="columns">
                        <div className="column has-text-centered">
                            <button className="button is-link" onClick={handleGeoJsonEditClick}>Add Location</button>
                        </div>
                    </div>
                ) : (
                    <GeoJsonDisplay narrativeId={narrativeId} />
                )}
            </div>
            <DeleteConfirmationModal
                isActive={isDeleteModalActive}
                onClose={closeDeleteModal}
                onConfirm={confirmDeleteLocation}
            />
        </div>
    );
};

const DeleteConfirmationModal = ({ isActive, onClose, onConfirm }) => (
    <div className={`modal ${isActive ? 'is-active' : ''}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
            <header className="modal-card-head">
                <p className="modal-card-title">Confirm Deletion</p>
                <button className="delete" aria-label="close" onClick={onClose}></button>
            </header>
            <section className="modal-card-body">
                Are you sure you want to delete this location?
            </section>
            <footer className="modal-card-foot">
                <button className="button is-danger" onClick={onConfirm}>Delete</button>
                <button className="button" onClick={onClose}>Cancel</button>
            </footer>
        </div>
    </div>
);

export default NarrativeGeoJson;