import React, { createContext, useEffect, useState } from "react";
import { API_BASE_URL } from '../config';

export const UserContext = createContext();

export const UserProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem("pm_api_token"));
    const [userId, setUserId] = useState(localStorage.getItem("pm_user_id"));

    useEffect(() => {
        const fetchUser = async () => {
            if (token === null) {
                localStorage.removeItem("pm_api_token");
                localStorage.removeItem("pm_user_id");
                return;
            }
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            };
            let resultToken = token;
            let resultUserId = userId;
            const response = await fetch(`${API_BASE_URL}/me`, requestOptions);
            if (!response.ok) {
                resultToken = null;
                resultUserId = null;
                setToken(null);
                setUserId(null);
            }
            localStorage.setItem("pm_api_token", resultToken);
            localStorage.setItem("pm_user_id", resultUserId);
        };
        fetchUser();
    }, [token]);

    return (
        <UserContext.Provider value={{ token, setToken, userId, setUserId }}>
            {props.children}
        </UserContext.Provider>
    );
};
