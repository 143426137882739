import React from 'react';

import '../static/Footer.css'; // Make sure to import the CSS file


const Footer = () => {
    return (
        <footer className="footer">
            <div className="content has-text-centered">
                <p>

                    GAIA Projection Mapping © 2024

                </p>
            </div>
        </footer>
    );
};

export default Footer;
