import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { Link } from 'react-router-dom';
import NarrativeModal from "./NarrativeModal";
import { API_BASE_URL } from "../config";

const Header = () => {
    const { token, setToken } = useContext(UserContext);
    const [activeModal, setActiveModal] = useState(false);

    useEffect(() => {
        const checkTokenValidity = async () => {
            if (token) {
                try {
                    const response = await fetch(`${API_BASE_URL}/me`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (!response.ok) {
                        throw new Error('Token is invalid');
                    }
                } catch (error) {
                    console.error('Token validation failed:', error);
                    setToken(null);
                }
            }
        };

        checkTokenValidity();
    }, [token, setToken]);

    const handleLogout = () => {
        setToken(null);
    };

    const handleModal = () => {
        setActiveModal(!activeModal);
    };

    return (
        <>
            <NarrativeModal active={activeModal} handleModal={handleModal} token={token} />

            <nav className="navbar" role="navigation" aria-label="main navigation">
                <div className="container">
                    <div id="navbarBasicExample" className="navbar-menu">
                        <div className="navbar-start">
                            <Link className="navbar-item" to="/">Home</Link>
                            <Link className="navbar-item" to="/example">Example</Link>
                            <Link className="navbar-item" to="/narratives">Narratives</Link>
                        </div>

                        <div className="navbar-end">
                            {token ? (
                                <div className="navbar-item">
                                    <Link to="/narratives/manage" className="button is-info mr-2">
                                        Manage Narratives
                                    </Link>
                                    <button className="button is-primary mr-2" onClick={handleModal}>
                                        Create Narrative
                                    </button>
                                    <Link to="/" className="button is-light" onClick={handleLogout}>
                                        Logout
                                    </Link>
                                </div>
                            ) : (
                                <div className="navbar-item">
                                    <div className="buttons">
                                        <Link to="/login" className="button is-light">
                                            Log in
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Header;
