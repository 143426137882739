import React from 'react';
import { API_BASE_URL } from '../config';

const Home = () => {
    return (
        <div className="container">
            <h1 className="title has-text-centered mt-6">GAIA Narratives</h1>
            <p className="subtitle has-text-centered mb-6">and Mappings</p>

            <div className="columns">
                <div className="column">
                    <div className="box">
                        <a href={`/example`}>
                            <h2 className="title is-4">View Example</h2>
                        </a>
                        <p>View a Narrative example.</p>
                    </div>
                </div>
                <div className="column">
                    <div className="box">
                        <a href={`/login`}>
                            <h2 className="title is-4">Get Started</h2>
                        </a>
                        <p>Create a new Narrative.</p>
                    </div>
                </div>
            </div>

            <div className="has-text-centered">
                <a className="button is-light" href={`${API_BASE_URL}/docs`}>View API Docs</a>
            </div>
        </div>
    );
};

export default Home;
