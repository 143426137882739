import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import ErrorMessage from "./ErrorMessage";
import { UserContext } from "../context/UserContext";
import NarrativeModal from "./NarrativeModal";
import { API_BASE_URL } from '../config';


const NarrativeManagement = () => {
    const { token } = useContext(UserContext);
    const [narratives, setNarratives] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [id, setId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/');
        } else {
            getNarratives();
        }
    }, [token, currentPage, navigate]);

    const handleViewDetails = async (id) => {
        navigate(`/narratives/${id}`);
    };

    const handleUpdate = async (id) => {
        navigate(`/narratives/${id}/edit`);
    };

    const DeleteConfirmationModal = ({ active, handleConfirm, handleCancel }) => {
        return (
            <div className={`modal ${active ? "is-active" : ""}`}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Confirm Delete</p>
                        <button className="delete" aria-label="close" onClick={handleCancel}></button>
                    </header>
                    <section className="modal-card-body">
                        <p>Are you sure you want to delete this narrative?</p>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-danger" onClick={handleConfirm}>Delete</button>
                        <button className="button" onClick={handleCancel}>Cancel</button>
                    </footer>
                </div>
            </div>
        );
    };

    const handleDelete = async () => {
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
        };

        const response = await fetch(`/narratives/${id}`, requestOptions);
        const data = await response.json();

        if (!response.ok) {
            setErrorMessage(data.detail);
        } else {
            getNarratives();
            setShowDeleteModal(false);
        }
    };

    const confirmDelete = (id) => {
        setId(id);
        setShowDeleteModal(true);
    };

    const getNarratives = async () => {
        const skip = (currentPage - 1) * limit;
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + token },
        };

        const response = await fetch(`${API_BASE_URL}/narratives?limit=${limit}&skip=${skip}`, requestOptions);
        const data = await response.json();

        if (!response.ok) {
            setErrorMessage(data.detail);
        } else {
            setNarratives(data.narratives); // Adjust according to the actual response structure
            const calculatedTotalPages = Math.ceil(data.total_count / limit);  // Assuming `totalCount` is part of the response
            setTotalPages(calculatedTotalPages);
            setLoaded(true);
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleModal = () => {
        setActiveModal(!activeModal);
        getNarratives();
        setId(null);
    };

    const renderPagination = () => {
        let pages = [];
        const pageRange = 2; // Number of pages to show around the current page

        const startPage = Math.max(1, currentPage - pageRange);
        const endPage = Math.min(totalPages, currentPage + pageRange);

        if (startPage > 1) {
            pages.push(
                <li key="first">
                    <button className="pagination-link" onClick={() => handlePageChange(1)}>1</button>
                </li>
            );
            if (startPage > 2) {
                pages.push(<li key="ellipsis-start"><span className="pagination-ellipsis">&hellip;</span></li>);
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li key={i}>
                    <button
                        className={`pagination-link ${i === currentPage ? "is-current" : ""}`}
                        aria-label={`Goto page ${i}`}
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </button>
                </li>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) {
                pages.push(<li key="ellipsis-end"><span className="pagination-ellipsis">&hellip;</span></li>);
            }
            pages.push(
                <li key="last">
                    <button className="pagination-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
                </li>
            );
        }

        return pages;
    };

    return (
        <>
            <NarrativeModal active={activeModal} handleModal={handleModal} token={token} id={id} setErrorMessage={setErrorMessage} />
            <DeleteConfirmationModal
                active={showDeleteModal}
                handleConfirm={handleDelete}
                handleCancel={() => setShowDeleteModal(false)}
            />
            <h2 className="title is-2 has-text-centered">Narrative Management</h2>

            <ErrorMessage message={errorMessage} />
            {loaded && narratives ? (
                <div>
                    {/* Titles or 'Headings' */}
                    <div className="columns is-vcentered has-text-weight-semibold">
                        <div className="column">
                            <p className="title is-5">Title</p>
                        </div>
                        <div className="column">
                            <p className="title is-5">Updated</p>
                        </div>
                        <div className="column">
                            <p className="title is-5">Actions</p>
                        </div>
                    </div>

                    {/* Data Rows */}
                    {narratives.map((narrative) => (
                        <div key={narrative.id} className="box">
                            <div className="columns is-vcentered">
                                <div className="column">
                                    <p>{narrative.published ? narrative.title : `[DRAFT] ${narrative.title}`}</p>
                                </div>
                                <div className="column">
                                    <p>{moment(narrative.updated_at).format("MMMM Do, YYYY, HH:mm")}</p>
                                </div>
                                <div className="column">
                                    <div className="buttons">
                                        <button className="button is-small is-primary is-light" onClick={() => handleViewDetails(narrative.id)}>View Details</button>
                                        <button className="button is-small is-info is-light" onClick={() => handleUpdate(narrative.id)}>Edit</button>
                                        <button className="button is-small is-danger is-light" onClick={() => confirmDelete(narrative.id)}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <button className="button is-fullwidth mt-5 mb-5 is-primary is-light" onClick={() => setActiveModal(true)}>
                        Create new narrative
                    </button>

                    {/* Pagination Controls */}
                    {totalPages > 1 && (
                        <nav className="pagination is-centered" role="navigation" aria-label="pagination">
                            {currentPage > 1 && (
                                <button className="pagination-previous" onClick={() => handlePageChange(currentPage - 1)}>Previous</button>
                            )}
                            {currentPage < totalPages && (
                                <button className="pagination-next" onClick={() => handlePageChange(currentPage + 1)}>Next</button>
                            )}
                            <ul className="pagination-list">
                                {renderPagination()}
                            </ul>
                        </nav>
                    )}
                </div>
            ) : <p className="has-text-centered">Loading...</p>}
        </>
    );
}

export default NarrativeManagement;
