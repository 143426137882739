import React, { useEffect } from "react";
import ErrorMessage from "./ErrorMessage";
import { API_BASE_URL } from '../config';

const CreateNarrativeModal = ({ active, handleModal, token, id }) => {
    const [title, setTitle] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");

    useEffect(() => {
        const getMap = async () => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            }
            const response = await fetch(`${API_BASE_URL}/narratives/${id}`, requestOptions);
            const data = await response.json();

            if (!response.ok) {
                setErrorMessage(data.detail);
            } else {
                setTitle(data.title);
            }
        }
        if (id) {
            getMap();
        }
    }, [id, token]);

    const cleanForm = () => {
        setTitle("");
    };

    const handleUpdateNarrative = async (e) => {
        e.preventDefault();

        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                title: title,
            })
        };

        const response = await fetch(`${API_BASE_URL}/narratives/${id}`, requestOptions);
        const data = await response.json();

        if (!response.ok) {
            setErrorMessage(data.detail[0].msg || "An error occurred while updating the narrative. Try logging in again.");
        } else {
            cleanForm();
            handleModal();
        }
    };

    const handleCreateNarrative = async (e) => {
        e.preventDefault();

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
            body: JSON.stringify({
                title: title,
            }),
        };

        const response = await fetch(`${API_BASE_URL}/narratives`, requestOptions);
        const data = await response.json();

        // get id from data
        const id = data.id;

        if (!response.ok) {
            setErrorMessage(data.detail[0].msg || "An error occurred while creating the narrative.");
        } else {
            cleanForm();
            handleModal();
            // redirect to the narrative
            console.log("redirecting to narrative page: " + `/narratives/${id}/edit`);
            window.location.href = `/narratives/${id}/edit`;
        }
    };

    return (
        <div className={`modal ${active && "is-active"}`}>
            <div className="modal-background" onClick={handleModal}></div>
            <div className="modal-card">
                <header className="modal-card-head has-background-primary-light">
                    <p className="modal-card-title has-text-primary-dark">
                        {id ? "Update Narrative" : "Create Narrative"}
                    </p>
                    <button
                        className="delete"
                        aria-label="close"
                        onClick={handleModal}
                    ></button>
                </header>
                <section className="modal-card-body">
                    <form onSubmit={id ? handleUpdateNarrative : handleCreateNarrative}>
                        <div className="field">
                            <label className="label">Title</label>
                            <div className="control">
                                <input
                                    type="text"
                                    placeholder="Enter title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="input is-medium"
                                    required
                                />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-heading"></i>
                                </span>
                            </div>
                        </div>
                        <ErrorMessage message={errorMessage} />
                    </form>
                </section>
                <footer className="modal-card-foot is-justify-content-flex-end">
                    <button
                        type="button"
                        className="button is-light"
                        onClick={handleModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className={`button ${id ? "is-info" : "is-primary"}`}
                        onClick={id ? handleUpdateNarrative : handleCreateNarrative}
                    >
                        {id ? "Update" : "Create"}
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default CreateNarrativeModal;
